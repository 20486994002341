import React from 'react';
import Logo from '@assets/blueshieldca.svg';
import Layout from '@components/layout';
import HeroText from '@components/sections/heroText/heroText';
import Seo from '@components/seo';
import CtaMailCard from '@components/sections/ctaMailCard/CtaMailCard';
import Testimonial from '@components/sections/testimonial/testimonial';
import SocialProofQuoteCard from '@components/sections/socialProofQuoteCard/socialProofQuoteCard';
import Segment from '@components/segment';
import { LANG_US_EN } from '@/src/common/consts/lang';
import { THEME_LIGHT } from '@/src/common/consts/theme';
import { SOURCE_EARLY_ACCESS } from '@/src/common/consts/tracking';

const IndexPage = () => (
  <Layout
    lang="us"
    headerWitdh="header"
    topHeader
    text="Covered by Blue Shield of California"
    dataTheme="white"
    showFooter
    blueshieldca
    headerImage={
      <img className="logo--md margin-left--xs flex" alt="BSCA" src={Logo} />
    }
  >
    <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_US} />
    <Seo title="Blue Shield of California" language="en" />

    <HeroText
      dataTheme={THEME_LIGHT}
      title="Treat joint and back pain from anywhere"
      text="Joint Academy is a digital treatment for joint and back pain. As a member of Blue Shield of California you're now eligible for early access with full coverage."
      heroTextMargin="margin-top--sm"
    />

    <CtaMailCard
      dataTheme={THEME_LIGHT}
      market="US"
      successPage="/early-access/granted/"
      source={SOURCE_EARLY_ACCESS}
    />

    <Testimonial
      dataTheme={THEME_LIGHT}
      renderTitle
      title="See how it works"
      titleClassName=""
      videoSrcURL="https://player.vimeo.com/video/605400985?color=fff&title=0&byline=0&portrait=0"
      videoTitle="“I can’t imagine a day without Joint Academy”"
    />

    <SocialProofQuoteCard
      lang={LANG_US_EN}
      dataTheme={THEME_LIGHT}
      title="Thousands of happy patients"
      quotes={[
        {
          name: 'Robert',
          text: 'My pain disappeared after a few weeks with Joint Academy.',
        },
        {
          name: 'Lotta',
          text:
              'It took me five minutes a day to make a difference and feel good.',
        },
        {
          name: 'Åke',
          text:
              'I don’t need any painkillers or surgery and I can still play tennis.',
        },
      ]}
    />
  </Layout>
);

export default IndexPage;
